<template>
  <div class="user-container main-container">
    <van-nav-bar
      :title="$t('mine')"
    >
    </van-nav-bar>
    <div class="user-main">
      <van-cell :title="$t('nickname')" :value="user.nickname" is-link @click="openEdit" />
      <van-cell :title="$t('email')" :value="user.email" />
      <!-- <van-cell :title="$t('lang')" :value="locale === 'zh-CN' ? '中文' : 'English'" @click="showPicker = true" /> -->
      <div class="user-logout">
        <van-button type="danger" block @click="doLogout">{{$t('logout')}}</van-button>
      </div>
    </div>
    <div class="user-tip">咨询邮箱：info@stoepx.com</div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        />
    </van-popup>

    <transition name="layer">
      <div class="edit-container" v-if="showEdit">
        <van-field v-model="nickname" :label="$t('nickname')" :placeholder="$t('nickname')" />
        <van-button type="warning" block round :loading="saving" :loading-text="$t('submiting')" @click="doSave">{{$t('submit')}}</van-button>
        <van-button type="default" block round @click="showEdit = false">{{$t('back')}}</van-button>
      </div>
    </transition>
  </div>
</template>

<script type="text/javascript">
import { mapState } from 'vuex'
import user from '@/api/user'
export default {
  data() {
    return {
      showPicker: false,
      showEdit: false,
      saving: false,
      nickname: '',
      columns: [
        { text: '中文', value: 'zh-CN' },
        { text: 'English', value: 'en-US' },
      ]
    }
  },
  computed: {
    ...mapState(['user', 'locale'])
  },
  methods: {
    openEdit() {
      this.nickname = this.user.nickname
      this.showEdit = true
    },
    doSave() {
      if (!this.nickname) {
        this.$toast(this.$t('required'))
        return false
      }
      this.saving = true
      user.update({ nickname: this.nickname })
      .then(() => {
        this.$toast('保存成功')
        this.$store.commit('SET_USER', {
          ...this.user,
          nickname: this.nickname
        })
        this.showEdit = false
        this.saving = false
      })
      .catch(() => {
        this.saving = false
      })
    },
    onConfirm({ value }) {
      this.$i18n.locale = value
      this.$store.dispatch('updateLocale', value)
      this.showPicker = false
    },
    doLogout() {
      this.$dialog.confirm({
        title: '提示',
        message: '您确定要退出登录吗？',
      })
      .then(() => {
        localStorage.removeItem('x-auth-token')
        window.location.href = '/login'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  &-logout {
    padding: 48px 16px;
  }
  &-tip {
    text-align: center;
    color: #666;
    font-size: 12px;
  }
}
.edit {
  &-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    z-index: 10;
    padding: 16px 16px;
    .van-button {
      margin-top: 20px;
    }
  }
}
</style>
